.warning-text {
  color: red;
  font-size: 30px;
  top: 20px;
}

.capitalize {
  .label {
    text-transform: capitalize;
  }
}

// #user-profile-popover 
.user-popover .user-image {
  display: block;
  clear: both;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 24px;
  height: 100px;
  width: 100px;
  text-align: center;
  border-radius: 50%;
}

.user-popover .user-name {
  font-size: large;
  font-weight: bold;
  text-align: center;
}

.user-popover .user-email {
  text-align: center;
}

.user-popover .user-phone{
  margin-top: 20px;
  text-align: center;
}

.user-popover {
  vega-button-link {
    .vega-button-link-content {
      color: #4B5563;
      padding-left: 20px;
    }
  }
}

.user-popover .user-signout{
  text-align: center;
}

#userEditProfileModal .vega-input
{
  margin-top: 24px;
}
