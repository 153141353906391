html {
  font-size: 16px;
  line-height: inherit;
}

vega-sidenav-link,
vega-sidenav-group {
  width: 324px;
}
.vega-sidebar.open {
  width: 340px;
}

vega-sidenav-group vega-sidenav-group,
vega-sidenav-group vega-sidenav-link {
  padding-left: 14px;
  max-width: 100%;
}

.logo {
  padding: 8px 24px 48px;
}

#table-container tr {
  height: 64px;
}

.logo img {
  height: 40px;
}

#menuSearch {
  margin-bottom: 32px;
}

.font-weight-bold {
  font-weight: bold;
}

.display-none {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}
