.home-container {
  .project-status {
    border: 1px solid #c7d9e5;
    border-radius: 12px;
  }
  .companyName{
    height:20px;
  }

  .edit-pen{
    display: inline-block;
    margin-top: 2px;
    margin-left: 10px;
    .svg-inline--fa{
      color:#666;
    }
  }
  .vertical-line{
    line-height:24px;
    width:1px;
    height:24px;
    background: #ABC6D8;
    display:inline-block;
    margin-left:10px;
  }
  .chip-bg{
    background: #f5f7f7;
  }
  .CR-Signal {
    display: inline-block;
    padding: 2px 8px;
    gap: 8px;
    width: 33px;
    height: 20px;
    background: #FF9571;
    border-radius: 9999px;
    color: #fff;
    text-align: center;
    margin-left: 6px;
    margin-top: 7px;
  }
  a.vega-link, .vega-link a {
    outline: none;
    cursor: pointer;
  }
}
